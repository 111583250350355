<div style="position: relative; min-height: 85vh">

    <!-- <div>
        <h1>Dashboard</h1>

        <h2>Valores da Telemetria</h2>
        <p>{{tanques ? (tanques | json) : 'Carregando...'}} </p>
    </div> -->


    <!-- Caminha para saber se está ativo ou inativo -->
    <!-- /api/device/info/{deviceId} -->


    <!-- <ng-container *ngIf="showGraphs">
        <div class="container-progress-bar">
            <div class="progress-bar-box">
                <div class="progress-bar-header">
                    <span style="font-size: 14px; color: #71717A">Encontre o tanque para exibir as informações</span>
                    <span style="font-size: 14 px; color: #71717A">{{ progressBarNumber }}/3</span>
                </div>
                <div class="progress-bar">
                    <div class="bar-progress" [ngStyle]="{'width': progressBarWidth + '%'}"></div>
                </div>
            </div>
        </div>

        <form [formGroup]="form">
            <div class="row g-3">
                <div class="col-4">
                    <label for="produtor" class="form-label required">Selecione o produtor</label>
                    <div class="input-group">
                        <select class="form-select" formControlName="nomeProdutor" [(ngModel)]="selectedProdutor"
                            (change)="onProdutorChange(selectedProdutor)" id="produtor">
                            <option *ngFor="let produtor of produtores" [value]="produtor.id">{{ produtor.nome }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-4">
                    <label for="propriedade" class="form-label required">Selecione a propriedade</label>
                    <div class="input-group">
                        <select class="form-select" formControlName="nomePropriedade"
                            [ngClass]="{'click-disabled': !selectedProdutor}" [(ngModel)]="selectedPropriedade"
                            (change)="onPropriedadeChange(selectedPropriedade)" id="propriedade">
                            <option *ngFor="let propriedade of propriedades" [value]="propriedade.id">
                                {{ propriedade.nome }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-4">
                    <label for="tanque" class="form-label required">Selecione o tanque</label>
                    <div class="input-group">
                        <select class="form-select" formControlName="nomeTanque"
                            [ngClass]="{'click-disabled': !selectedPropriedade}" [(ngModel)]="selectedTanque"
                            (change)="onTanqueChange(selectedTanque)" id="tanque">
                            <option *ngFor="let tanque of tanques" [value]="tanque.id">{{ tanque.nome }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>

        <div class="actions-footer">
            <button class="button-no-border" style="margin-right: 10px" (click)="cleanInput()">Limpar</button>
            <button [disabled]="!form.valid || !form.dirty" (click)="onSubmit()"
                [ngClass]="{'button-disabled': !form.valid || !form.dirty}">Mostrar dados
            </button>
        </div>
    </ng-container> -->

    <ng-container *ngIf="showGraphs">
        <div style="width: 100%; display: flex; justify-content: end; padding-right: 40px;">
            <button class="button-no-border" (click)="cleanInput()">Nova consulta</button>
        </div>
    </ng-container>


    <ng-container *ngIf="!showGraphs">
        <div class="main-container">
            <div class="main-container-header">
                <!-- <span>{{ nomePropriedade }}, de {{ nomeProdutor }}</span> -->
                <!-- <span style="color: #616161; padding-bottom: 20px;">{{ nomeTanque }}</span> -->
                <select class="form-select input-name-selected" [(ngModel)]="selectedTanque" (change)="onTanqueBoxNameChange()">
                    <option *ngIf="selectedTanque" disabled style="color: #616161; display: none;">
                        {{ nomeTanque }}
                    </option>
                    <option *ngFor="let tanque of tanques" [value]="tanque.id" style="color: #616161;">
                        {{ formatarNomeTanqueId(tanque.id) }}
                    </option>
                </select>
            </div>

            <div class="main-container-body">
                <div class="row" style="padding: 0 48px; padding-bottom: 40px;">
                    <div class="col-4">
                        <div class="graph-container">
                            <div class="graph-header">
                                <div>
                                    <span>Aeradores</span>
                                    <!-- <div class="exclamation-icon" (click)="openGraph('aerador')">i</div> -->
                                </div>
                                <span>Número de aeradores totais</span>
                            </div>

                            <div class="aerator-graph-container">
                                <div echarts [options]="chartOptionAerador" style="height: 280px;"></div>
                                <div class="aerator-info-card">
                                    <div class="current-aerator">{{ aeradoresAtivos + aeradoresInativos }} un</div>
                                    <span>Aeradores</span>
                                    <span>totais</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="graph-container">
                            <div class="graph-header">
                                <div>
                                    <span>PH da água (Em produção)</span>
                                    <div class="exclamation-icon" (click)="openGraph('ph')">i</div>
                                </div>
                                <span>Nível de PH da água (Em produção)</span>
                            </div>
                            <div>
                                <div echarts [options]="chartOptionPH" style="height: 280px;"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="graph-container">
                            <div class="graph-header">
                                <div>
                                    <span>Temperatura</span>
                                    <div class="exclamation-icon" (click)="openGraph('temperatura')">i</div>
                                </div>
                                <span>Temperatura da água</span>
                            </div>
                            <div class="temp-info-card">
                                <div class="current-temp">{{ currentTemperatura }}°</div>
                                <span class="last-read">Última leitura {{ lastHour }}</span>
                                <div class="max-min-temp-box">
                                    <div>
                                        <span class="max-min-temp-label">Máx</span>
                                        <span class="max-min-temp">{{ temperaturaMax }}°</span>
                                    </div>
                                    <div>
                                        <span class="max-min-temp-label">Min</span>
                                        <span class="max-min-temp">{{ temperaturaMin }}°</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" style="padding: 0 48px;">
                    <div class="col-4">
                        <div class="graph-container">
                            <div class="graph-header">
                                <div>
                                    <span>Saturação</span>
                                    <div class="exclamation-icon" (click)="openGraph('saturação')">i</div>
                                </div>
                                <span>Histórico de saturação</span>
                            </div>
                            <div>
                                <div echarts [options]="chartOptionSaturacao" style="height: 280px;"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-8">
                        <div class="graph-container">
                            <div class="graph-header">
                                <div>
                                    <span>Oxigênio</span>
                                    <div class="exclamation-icon" (click)="openGraph('oxigênio')">i</div>
                                </div>
                                <span>Nível de oxigenação</span>
                            </div>
                            <div>
                                <div echarts [options]="chartOptionOxigenio" style="height: 280px;"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="main-container-footer">
                <div id="paginacao" class="footer-content-box">
                    <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)"
                        [ngClass]="{'hidden-arrow-table': pageNumber == 1 }">arrow_right_alt</mat-icon>
                    <div class="flex-align-itens-center">
                        <ng-container *ngFor="let number of getSequence(totalPage)">
                            <span id="paginacao-number" class="number-page" (click)="chancePageByNumberClick(number)"
                                [ngClass]="{'current-page': number === pageNumber}">{{ number }}</span>
                        </ng-container>
                    </div>
                    <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)"
                        [ngClass]="{'hidden-arrow-table': pageNumber == totalPage }">arrow_right_alt</mat-icon>
                </div>
            </div>
        </div>
    </ng-container>

    <div id="filterWall" (click)="closeConfirm()"></div>

    <div id="graphPopUp">
        <div class="graph-popup-header">
            <div>
                <mat-icon class="icon-popup material-symbols-rounded">{{ popIcon }}</mat-icon>
                <span class="title-popup">{{ popTitle }}</span>
            </div>
            <mat-icon class="icon-close material-symbols-rounded" (click)="closeConfirm()">close</mat-icon>
        </div>

        <div class="graph-popup-body">
            <div class="graph-popup-second-header">
                <span>{{ popText }}</span>
                <div class="type-buttons-box">
                    <!-- <span *ngFor="let option of ['Dia', 'Semana', 'Mês', 'Ano']" (click)="selectDateOption(option)" -->
                    <!-- <span *ngFor="let option of ['Dia', 'Mês', 'Ano']" (click)="selectDateOption(option)" -->
                    <span *ngFor="let option of ['Dia']" (click)="selectDateOption(option)"
                        [ngClass]="{'type-button-selected': selectedDateOption === option}">
                        {{ option }}
                    </span>
                </div>
            </div>
            <div>
                <div echarts [options]="chartOption" style="height: 280px;"></div>
            </div>
        </div>
    </div>

</div>