import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DadosApiService {

    private serverUrl: string = 'https://iot.diplab.com.br';
    private deviceId: string = '51cceb40-09b5-11f0-8e32-37cd52133d87';
    private username: string = 'gildo@soluvel.com.br';
    private password: string = 'Aa030201';

    constructor(private http: HttpClient) { }

    // Método para obter o token JWT
    obterToken(): Observable<any> {
        const body = { username: this.username, password: this.password };
        return this.http.post<any>(`${this.serverUrl}/api/auth/login`, body, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        });
    }

    // Método para obter os valores atuais dos atributos
    obterValoresAtributos(token: string): Observable<any> {
        return this.http.get<any>(`${this.serverUrl}/api/plugins/telemetry/DEVICE/${this.deviceId}/values/attributes`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Authorization': `Bearer ${token}`
            })
        });
    }

    // Método para obter os valores atuais da telemetria
    obterValoresTelemetria(token: string): Observable<any> {
        return this.http.get<any>(`${this.serverUrl}/api/plugins/telemetry/DEVICE/${this.deviceId}/values/timeseries`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Authorization': `Bearer ${token}`
            })
        });
    }

    // Método para obter valores das últimas 6 horas com intervalo de 30 em 30 minutos
    obterValoresUltimas6HorasComIntervalo(token: string, keys: string, startTs: number, endTs: number, interval: number): Observable<any> {
        const url = `${this.serverUrl}/api/plugins/telemetry/DEVICE/${this.deviceId}/values/timeseries`;

        const params = {
            keys: keys,  // Chaves dos atributos (ex: 'gas,temperature')
            startTs: startTs.toString(),  // Timestamp de início (6 horas atrás)
            endTs: endTs.toString(),  // Timestamp de fim (agora)
            interval: interval.toString(),  // Intervalo de 30 minutos em milissegundos
            limit: '100',  // Limite de registros, ajuste conforme necessário
            agg: 'AVG'  // Média (pode ser alterado conforme necessidade)
        };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Authorization': `Bearer ${token}`
        });

        return this.http.get<any>(url, { headers, params });
    }

    // Método para obter valores das últimas 6 horas com intervalo de 1 em 1 hora
    obterValoresUltimas6Horas(token: string, keys: string, startTs: number, endTs: number, interval: number): Observable<any> {
        const url = `${this.serverUrl}/api/plugins/telemetry/DEVICE/${this.deviceId}/values/timeseries`;

        const params = {
            keys: keys,  // Chaves dos atributos (ex: 'gas,temperature')
            startTs: startTs.toString(),  // Timestamp de início (6 horas atrás)
            endTs: endTs.toString(),  // Timestamp de fim (agora)
            interval: interval.toString(),  // Intervalo de 30 minutos em milissegundos
            limit: '100',  // Limite de registros, ajuste conforme necessário
            agg: 'AVG'  // Média (pode ser alterado conforme necessidade)
        };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Authorization': `Bearer ${token}`
        });

        return this.http.get<any>(url, { headers, params });
    }

    // Método para obter valores do dia todo
    obterValoresDoDiaTodo(token: string, keys: string, startTs: number, endTs: number, interval: number): Observable<any> {
        const url = `${this.serverUrl}/api/plugins/telemetry/DEVICE/${this.deviceId}/values/timeseries`;

        const params = {
            keys: keys,  // Chaves dos atributos (ex: 'gas,temperature')
            startTs: startTs.toString(),  // Timestamp de início (6 horas atrás)
            endTs: endTs.toString(),  // Timestamp de fim (agora)
            interval: interval.toString(),  // Intervalo de 30 minutos em milissegundos
            limit: '100',  // Limite de registros, ajuste conforme necessário
            agg: 'AVG'  // Média (pode ser alterado conforme necessidade)
        };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Authorization': `Bearer ${token}`
        });

        return this.http.get<any>(url, { headers, params });
    }


    obterValoresEscolhendoData(token: string, keys: string, startTs: number, endTs: number, interval: number): Observable<any> {
        const url = `${this.serverUrl}/api/plugins/telemetry/DEVICE/${this.deviceId}/values/timeseries`;

        const params = {
            keys: keys,  // Chaves dos atributos (ex: 'gas,temperature')
            startTs: startTs.toString(),  // Timestamp de início (6 horas atrás)
            endTs: endTs.toString(),  // Timestamp de fim (agora)
            interval: interval.toString(),  // Intervalo de 30 minutos em milissegundos
            limit: '100',  // Limite de registros, ajuste conforme necessário
            agg: 'AVG'  // Média (pode ser alterado conforme necessidade)
        };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Authorization': `Bearer ${token}`
        });

        return this.http.get<any>(url, { headers, params });
    }

}
