import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmpresaService } from "../empresa/empresa.service";
import { StorageService } from "../storage.service";
import { Subject, takeUntil } from "rxjs";
import { tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { StringNumberFormats } from "../utils/StringNumberFormats";
import { provideEcharts } from 'ngx-echarts';
import { EChartsOption, number } from 'echarts';
import { DadosApiTemporariaService } from '../dados-api-temporaria.service';
import { DadosApiService } from '../dados-api.service';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
  providers: [
    provideEcharts(),
  ]
})
export class DashboardsComponent implements OnInit {

  form: FormGroup;
  showGraphs: boolean;

  popIcon: string;
  popTitle: string;
  popText: string;

  produtores: any[] = [];
  propriedades: any[] = [];
  tanques: any[] = [];
  aeradores: any[] = [];
  leiturasTanque: any[] = [];
  leiturasTanqueOxigenio: any[] = [];
  leituraAtualTanque: any[] = [];
  leiturasPopup: any[] = [];

  nomeProdutor: string;
  nomePropriedade: string;
  nomeTanque: string;

  ph: number[] = [];
  saturacao: number[] = [];
  oxigenio: number[] = [];

  graphType: string;
  graphValue: any;
  graphResponse: any;
  temperaturaPopup: number[] = [];
  saturacaoPopup: number[] = [];
  oxigenioPopup: number[] = [];
  GraphMaxTemperatures: number[] = [];
  GraphMinTemperatures: number[] = [];


  aeradoresAtivos: number;
  aeradoresInativos: number;
  currentPh: string;
  currentTemperatura: string;
  temperaturaMax: string;
  temperaturaMin: string;
  currentSaturacao: string;
  currentOxigenio: string;
  lastHour: string;

  selectedProdutor: string;
  selectedPropriedade: string;
  selectedTanque: string;

  selectedDateOption: string = 'Dia';

  // Agora as opções de gráficos são do tipo EChartsOption
  chartOptionAerador: EChartsOption = {};
  chartOptionPH: EChartsOption = {};
  chartOptionTemperatura: EChartsOption = {};
  chartOptionSaturacao: EChartsOption = {};
  chartOptionOxigenio: EChartsOption = {};
  chartOption: EChartsOption = {};

  // Dados de exemplo
  labelsHoursSaturation: string[] = []; // Labels de tempo das últimas 6 horas do dia
  labelsForPopup: string[] = []; // Labels de tempo do dia todo
  labelsHoursOxygen: string[] = []; // Labels de tempo de 30 em 30 minutos
  labelsDays: string[] = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6']; // Labels de tempo

  pageNumber: number;
  totalPage: number;
  size: number = 5;
  lastPage: boolean = false;

  progressBarWidth: number = 0;
  progressBarNumber: string = '0';


  token: string = '';
  valoresAtributos: any;
  valoresTelemetria: any;
  valoresTelemetriaAeradores: any;
  chavesTelemetria: string[] = [];  // Adicionando um array para armazenar as chaves da telemetria

  valoresUltimas6HorasOxigenio: any;
  valoresUltimas6HorasSaturacao: any;
  valoresUltimas6HorasTemperatura: any;

  valoresDiaTodoOxigenio: any;
  valoresDiaTodoSaturacao: any;
  valoresDiaTodoTemperatura: any;



  constructor(
    private formBuilder: FormBuilder,
    public storage: StorageService,
    private apiService: DadosApiTemporariaService,
    private dadosApiService: DadosApiService
  ) {
    this.form = this.formBuilder.group({
      nomeProdutor: ['', Validators.required],
      nomePropriedade: ['', Validators.required],
      nomeTanque: ['', Validators.required],
    });
  }

  ngOnInit(): void {

    this.selectedTanque = '_1';

    this.onSubmit();
  }

  // Obtém o token JWT e faz as requisições subsequentes
  obterToken(tanqueId: any): void {
    this.dadosApiService.obterToken().subscribe(response => {
      this.token = response.token;
      // console.log('Token JWT obtido:', this.token);

      // Após obter o token, requisita os atributos de 6 horas antes e os valores atuais
      this.obterValoresUltimas6HorasComIntervaloDe30Minutos(tanqueId, 'Oxig');
      this.obterValoresUltimas6HorasComIntervaloDe1Hora(tanqueId, 'Sat');
      this.obterValoresDoDiaTodo(tanqueId, 'Temp');
      this.obterAeradores(tanqueId);

      setTimeout(() => {
        // Após o timeout de 2 segundos, chama a função
        this.obterValoresTelemetria(tanqueId);
      }, 1000);
    });
  }

  // Obtém valores das últimas 6 horas a cada 30 minutos
  obterValoresUltimas6HorasComIntervaloDe30Minutos(tanqueId: any, tipo: any): void {
    const now = Date.now();  // Timestamp atual
    const startTs = now - (6 * 60 * 60 * 1000);  // 6 horas atrás em milissegundos
    const endTs = now;  // Timestamp atual (fim)

    const interval = 30 * 60 * 1000;  // Intervalo de 30 minutos em milissegundos

    // Exemplo de chaves, substitua conforme os dados que você quer consultar
    const keys = `Tq_${tanqueId}_${tipo}`;  // Modifique com suas chaves reais

    // Chama o serviço para obter os dados das últimas 6 horas
    this.dadosApiService.obterValoresUltimas6HorasComIntervalo(this.token, keys, startTs, endTs, interval).subscribe(response => {
      if (tipo == 'Temp') {
        this.valoresUltimas6HorasTemperatura = response;
        // console.log('Valores das últimas 6 horas Temp:', this.valoresUltimas6HorasTemperatura);

      } else if (tipo == 'Sat') {
        this.valoresUltimas6HorasSaturacao = response;
        // console.log('Valores das últimas 6 horas Sat:', this.valoresUltimas6HorasSaturacao);

      } else if (tipo == 'Oxig') {
        this.valoresUltimas6HorasOxigenio = response;
        // console.log('Valores das últimas 6 horas Oxig:', this.valoresUltimas6HorasOxigenio);

      }
      // Aqui você pode processar a resposta, se necessário
    });
  }

  // Obtém valores das últimas 6 horas a cada hora
  obterValoresUltimas6HorasComIntervaloDe1Hora(tanqueId: any, tipo: any): void {
    const now = Date.now();  // Timestamp atual
    const startTs = now - (6 * 60 * 60 * 1000);  // 6 horas atrás em milissegundos
    const endTs = now;  // Timestamp atual (fim)

    const interval = 60 * 60 * 1000;  // Intervalo de 30 minutos em milissegundos

    // Exemplo de chaves, substitua conforme os dados que você quer consultar
    const keys = `Tq_${tanqueId}_${tipo}`;  // Modifique com suas chaves reais

    // Chama o serviço para obter os dados das últimas 6 horas
    this.dadosApiService.obterValoresUltimas6HorasComIntervalo(this.token, keys, startTs, endTs, interval).subscribe(response => {
      if (tipo == 'Sat') {
        this.valoresUltimas6HorasSaturacao = response;

      } else if (tipo == 'Temp') {
        this.valoresUltimas6HorasTemperatura = response;

      } else if (tipo == 'Oxig') {
        this.valoresUltimas6HorasOxigenio = response;

      }

      // Aqui você pode processar a resposta, se necessário
    });
  }

  // Obtém valores do dia inteiro
  obterValoresDoDiaTodo(tanqueId: any, tipo: any): void {
    const now = new Date();  // Obtém o timestamp atual como um objeto Date

    // Define a hora para 00:00
    now.setHours(0, 0, 0, 0);

    const startTs = now.getTime();  // Obtemos o timestamp de início (00:00)
    const endTs = Date.now();

    const interval = 30 * 60 * 1000;  // Intervalo de 30 minutos em milissegundos

    // Exemplo de chaves, substitua conforme os dados que você quer consultar
    const keys = `Tq_${tanqueId}_${tipo}`;  // Modifique com suas chaves reais

    // Chama o serviço para obter os dados das últimas 6 horas
    this.dadosApiService.obterValoresUltimas6HorasComIntervalo(this.token, keys, startTs, endTs, interval).subscribe(response => {
      if (tipo == 'Sat') {
        this.valoresDiaTodoSaturacao = response;

      } else if (tipo == 'Temp') {
        this.valoresDiaTodoTemperatura = response;
        // console.log(this.valoresDiaTodoTemperatura)

      } else if (tipo == 'Oxig') {
        this.valoresDiaTodoOxigenio = response;

      }
      // Aqui você pode processar a resposta, se necessário
    });
  }

  // Obtém valores do dia
  getDia(tanqueId: any, tipo: any): void {
    const now = new Date();  // Obtém o timestamp atual como um objeto Date

    // Define a hora para 00:00
    now.setHours(0, 0, 0, 0);

    const startTs = now.getTime();  // Obtemos o timestamp de início (00:00)
    const endTs = Date.now();

    const interval = 30 * 60 * 1000;  // Intervalo de 30 minutos em milissegundos

    // Exemplo de chaves, substitua conforme os dados que você quer consultar
    const keys = `Tq_${tanqueId}_${tipo}`;  // Modifique com suas chaves reais

    // Chama o serviço para obter os dados das últimas 6 horas
    this.dadosApiService.obterValoresEscolhendoData(this.token, keys, startTs, endTs, interval).subscribe(response => {
      if (tipo == 'Sat' || tipo == 'Temp' || tipo == 'Oxig') {
        this.graphResponse = response;
        this.graphValue = this.graphResponse[keys].map(item => parseFloat(item.value).toFixed(2));
        this.labelsForPopup = this.graphResponse[keys].map(item => this.convertEpochToDate(parseFloat(item.ts)));

        // console.log(this.graphValue)
      }
      // Aqui você pode processar a resposta, se necessário
    });
  }

  getDiaSoTemperatura(tanqueId: any, tipo: any = 'Temp'): void {
    const now = new Date();  // Obtém o timestamp atual como um objeto Date

    // Define a hora para 00:00
    now.setHours(0, 0, 0, 0);

    const startTs = now.getTime();  // Obtemos o timestamp de início (00:00)
    const endTs = Date.now();

    const interval = 15 * 60 * 1000;  // Intervalo de 15 minutos em milissegundos

    // Exemplo de chaves, substitua conforme os dados que você quer consultar
    const keys = `Tq_${tanqueId}_${tipo}`;  // Modifique com suas chaves reais

    // Chama o serviço para obter os dados das últimas 6 horas
    this.dadosApiService.obterValoresEscolhendoData(this.token, keys, startTs, endTs, interval).subscribe(response => {
      if (tipo == 'Sat' || tipo == 'Temp' || tipo == 'Oxig') {
        this.graphResponse = response;
        this.graphValue = this.graphResponse[keys].map(item => parseFloat(item.value).toFixed(2));  // Temperaturas
        this.labelsForPopup = this.graphResponse[keys].map(item => this.convertEpochToDate(parseFloat(item.ts)));  // Labels com timestamps

        // Processando os valores para min e max
        let i = 0;
        while (i < this.graphValue.length) {
          if (i + 1 < this.graphValue.length) {
            // Pega o máximo e mínimo de dois valores consecutivos
            const maxValue = Math.max(parseFloat(this.graphValue[i]), parseFloat(this.graphValue[i + 1])).toFixed(2);
            const minValue = Math.min(parseFloat(this.graphValue[i]), parseFloat(this.graphValue[i + 1])).toFixed(2);

            this.GraphMaxTemperatures.push(Number(maxValue));
            this.GraphMinTemperatures.push(Number(minValue));

            i += 2; // Avança 2 posições
          } else {
            // Caso o número de valores seja ímpar, coloca o último valor no máximo e 0 no mínimo
            this.GraphMaxTemperatures.push(this.graphValue[i]);
            this.GraphMinTemperatures.push(0);
            i++; // Avança 1 posição
          }
        }

        // Ajustando labels para 30 minutos
        const adjustedLabels = [];
        for (let j = 0; j < this.labelsForPopup.length; j++) {
          if (j % 2 === 0) {
            adjustedLabels.push(this.labelsForPopup[j]);
          }
        }

        // Se o número de valores for ímpar, adicionar o último label
        if (this.labelsForPopup.length % 2 !== 0) {
          adjustedLabels.push(this.labelsForPopup[this.labelsForPopup.length - 1]);
        }

        // Atualizando o array de labels
        this.labelsForPopup = adjustedLabels;

        // Debugando os resultados
        // console.log("Temperaturas máximas:", this.GraphMaxTemperatures);
        // console.log("Temperaturas mínimas:", this.GraphMinTemperatures);
        // console.log("Labels ajustados:", this.labelsForPopup);
      }
    });
  }


  // Obtém valores do Mês
  getMes(tanqueId: any, tipo: any): void {
    const now = new Date();  // Obtém o timestamp atual como um objeto Date

    // Define a hora para 00:00
    now.setHours(0, 0, 0, 0);

    const startTs = now.getTime();  // Obtemos o timestamp de início (00:00)
    const endTs = Date.now();

    const interval = 30 * 60 * 1000;  // Intervalo de 30 minutos em milissegundos

    // Exemplo de chaves, substitua conforme os dados que você quer consultar
    const keys = `Tq_${tanqueId}_${tipo}`;  // Modifique com suas chaves reais

    // Chama o serviço para obter os dados das últimas 6 horas
    this.dadosApiService.obterValoresEscolhendoData(this.token, keys, startTs, endTs, interval).subscribe(response => {
      if (tipo == 'Sat' || tipo == 'Temp' || tipo == 'Oxig') {
        this.graphResponse = response;
        this.graphValue = this.graphResponse[keys].map(item => parseFloat(item.value).toFixed(2));
        this.labelsForPopup = this.graphResponse[keys].map(item => this.convertEpochToDate(parseFloat(item.value)));

        // console.log(this.graphValue)
        // console.log(this.labelsForPopup)
      }
      // Aqui você pode processar a resposta, se necessário
    });
  }

  // Obtém valores do ano
  getAno(tanqueId: any, tipo: any): void {
    const now = new Date();  // Obtém o timestamp atual como um objeto Date

    // Define a hora para 00:00
    now.setHours(0, 0, 0, 0);

    const startTs = now.getTime();  // Obtemos o timestamp de início (00:00)
    const endTs = Date.now();

    const interval = 60 * 60 * 1000;  // Intervalo de 30 minutos em milissegundos

    // Exemplo de chaves, substitua conforme os dados que você quer consultar
    const keys = `Tq_${tanqueId}_${tipo}`;  // Modifique com suas chaves reais

    // Chama o serviço para obter os dados das últimas 6 horas
    this.dadosApiService.obterValoresEscolhendoData(this.token, keys, startTs, endTs, interval).subscribe(response => {
      if (tipo == 'Sat' || tipo == 'Temp' || tipo == 'Oxig') {
        this.graphResponse = response;
        this.graphValue = this.valoresDiaTodoTemperatura[keys].map(item => parseFloat(item.value).toFixed(2));
        this.labelsForPopup = this.valoresDiaTodoTemperatura[keys].map(item => this.convertEpochToDate(parseFloat(item.value)));

        // console.log(this.graphValue)
        // console.log(this.labelsForPopup)
      }
      // Aqui você pode processar a resposta, se necessário
    });
  }


  obterValoresTelemetria(tanqueId: any): void {
    this.dadosApiService.obterValoresTelemetria(this.token).subscribe(response => {
      this.valoresTelemetria = response;

      // Estrutura para armazenar os tanques
      for (const key in this.valoresTelemetria) {
        if ((key.includes('Oxig') && !key.includes('Oxigenio')) || (key.includes('Sat') && !key.includes('Saturacao')) || (key.includes('Temp') && !key.includes('Temperatura'))) {

          if (this.valoresTelemetria.hasOwnProperty(key)) {
            // Extrai a informação do tanque (ex: "Tq_1_Oxig" vira "Tq_1")
            const tanqueId = key.split('_').slice(0, 2).join('_'); // Ex: "Tq_1"

            // Verifica se já existe o tanque na lista, caso não, cria um novo
            let tanque = this.tanques.find(t => t.id === tanqueId);
            if (!tanque) {
              tanque = { id: tanqueId, oxigenio: null, saturacao: null, temperatura: null };
              this.tanques.push(tanque);
            }

            // Preenche os dados conforme o tipo
            if (key.includes('Oxig')) {
              tanque.oxigenio = {
                value: parseFloat(this.valoresTelemetria[key][0].value).toFixed(2),
                ts: this.valoresTelemetria[key][0].ts
              };
            } else if (key.includes('Sat')) {
              tanque.saturacao = {
                value: parseFloat(this.valoresTelemetria[key][0].value).toFixed(2),
                ts: this.valoresTelemetria[key][0].ts
              };
            } else if (key.includes('Temp')) {
              tanque.temperatura = {
                value: parseFloat(this.valoresTelemetria[key][0].value).toFixed(2),
                ts: this.valoresTelemetria[key][0].ts
              };
            }
          }
        }
      }

      // Ordena os tanques pela parte numérica do ID (ex: 'Tq_1', 'Tq_2', ..., 'Tq_12')
      this.tanques.sort((a, b) => {
        const numA = parseInt(a.id.split('_')[1], 10);  // Extrai o número do ID de 'Tq_1', 'Tq_2', etc.
        const numB = parseInt(b.id.split('_')[1], 10);  // Extrai o número do ID de 'Tq_1', 'Tq_2', etc.
        return numA - numB;  // Ordena de forma crescente
      });

      this.totalPage = this.tanques.length;

      // Exibe os tanques ordenados no console
      // console.log(this.tanques);
      this.gerarGraficos(tanqueId - 1);
    });
  }


  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }


  getTemperaturaMaxMinDia(tanqueId: any): void {
    const chaveTemp = `Tq_${tanqueId + 1}_Temp`;

    const temperaturas = this.valoresDiaTodoTemperatura[chaveTemp].map(item => parseFloat(item.value).toFixed(2));

    // console.log(temperaturas)

    // Encontrando a temperatura máxima e mínima do dia inteiro
    this.temperaturaMax = String(Math.max(...temperaturas));
    this.temperaturaMin = String(Math.min(...temperaturas));
  }


  // Formata os valores "Tq_x" para "Tanque x"
  formatarNomeTanqueId(tanqueId: string): string {
    const id = tanqueId.replace('Tq_', ''); // Remove 'Tq_'
    return `Tanque ${id}`;
  }


  // Obtém a quantidade de aeradores e se estão ativos
  obterAeradores(tanqueId: any): void {

    this.aeradores = [];

    this.dadosApiService.obterValoresTelemetria(this.token).subscribe(response => {
      this.valoresTelemetriaAeradores = response;

      // Estrutura para armazenar os aeradores
      for (const key in this.valoresTelemetriaAeradores) {
        if (key.includes(`Tq_${tanqueId}_Status_G`)) {

          if (this.valoresTelemetriaAeradores.hasOwnProperty(key)) {

            const aeradorId = key;

            // Verifica se já existe o aerador na lista, caso não, cria um novo
            let aerador = this.aeradores.find(t => t.id === aeradorId);
            if (!aerador) {
              let ativo = this.valoresTelemetriaAeradores[key][0].value === 'true'

              aerador = { id: aeradorId, ativo };
              this.aeradores.push(aerador);
            }
          }
        }
      }

      this.aeradoresAtivos = this.aeradores.filter(a => a.ativo === true).length;
      this.aeradoresInativos = this.aeradores.filter(a => a.ativo === false).length;
    });
  }


  // Quando um produtor é selecionado, carrega suas propriedades
  // onProdutorChange(produtorId: string): void {
  //   if (produtorId) {
  //     this.apiService.getPropriedadesPorProdutor(produtorId).subscribe((dados) => {
  //       this.propriedades = dados;
  //       this.tanques = [];
  //       this.form.controls['nomePropriedade'].setValue('');
  //       this.form.controls['nomeTanque'].setValue('');
  //     });
  //   } else {
  //     this.propriedades = [];
  //   }
  // }

  onProdutorChange(produtorId: string): void {
    this.updateProgressBar();
  }


  onPropriedadeChange(propriedadeId: string): void {
    this.updateProgressBar();

    this.tanques = [];

    if (propriedadeId) {
      this.apiService.getTanquesPorPropriedade(propriedadeId).subscribe((dados) => {
        this.tanques = dados;
        this.selectedTanque = '';
      });
    }
  }

  onTanqueChange(tanqueId: string): void {
    this.updateProgressBar();
  }

  onTanqueBoxNameChange() {

    // console.log(this.selectedTanque)
    this.onSubmit();
  }


  updateProgressBar(): void {
    let filledCount = 0;

    // Verifica quantos selects foram preenchidos
    if (this.selectedProdutor) filledCount++;
    if (this.selectedPropriedade) filledCount++;
    if (this.selectedTanque) filledCount++;

    // A largura da barra será 33% para cada input preenchido
    this.progressBarWidth = filledCount * 33.3333;
    this.progressBarNumber = String(filledCount);
  }


  getNumberPages(idTanque: string) {
    this.pageNumber = (this.tanques.findIndex(tanque => tanque.id === idTanque)) + 1;

    if (this.pageNumber == 0) {
      this.pageNumber = 1;
    }
  }

  nextOrBack(isAvancar: boolean) {
    this.pageNumber = isAvancar ? this.pageNumber + 1 : this.pageNumber - 1;
    this.selectedTanque = this.tanques[this.pageNumber - 1]['id'];
    this.onSubmit();

  }

  chancePageByNumberClick(numberPageClick: number) {
    this.selectedTanque = this.tanques[numberPageClick - 1]['id'];
    this.onSubmit();
  }

  getSequence(num: number): number[] {
    return Array.from({ length: num }, (_, i) => i + 1);
  }

  paginado(number: number) {
    // this.service.page(number - 1, this.size, '', []).subscribe({
    //   next: (page) => {
    //     this.dataSource.data = page.content
    //     this.pageNumber = page.pageable.pageNumber
    //     this.totalPage = page.totalPages
    //     this.lastPage = page.last
    //   }, error: () => {
    //   }
    // });
  }

  onSubmit() {

    this.obterToken((this.selectedTanque).split('_')[1]);

    this.getNumberPages(this.selectedTanque);

  }

  // settarInfos() {
  //   // Setta dos dados mais recentes
  //   if (this.leituraAtualTanque) {
  //     this.aeradoresAtivos = this.leituraAtualTanque['aeradores'];
  //     this.currentTemperatura = this.leituraAtualTanque['temperatura_max'];
  //     this.lastHour = this.leituraAtualTanque['hora'] + ':00';

  //   } else {
  //     alert('Esse tanque não possui registros ou não não há registros novos há muito tempo.')
  //     this.aeradoresAtivos = 0;
  //     this.temperaturaMax = '0';
  //     this.temperaturaMin = '0';
  //     this.lastHour = 'desconhecida';
  //   }

  //   this.nomeProdutor = this.produtores.find(p => p.id === this.selectedProdutor)['nome'];
  //   this.nomePropriedade = this.propriedades.find(p => p.id === this.selectedPropriedade)['nome'];
  //   this.nomeTanque = this.tanques.find(t => t.id === this.selectedTanque)['nome'];
  //   this.aeradoresInativos = this.tanques.find(t => t.id === this.selectedTanque)['aeradores'] - this.aeradoresAtivos;
  // }


  // getPH() {
  //   this.ph = this.leiturasTanque.map((leituras: any) => leituras.ph).slice(-7);

  //   this.gerarGraficoPH();
  // }

  // getSaturacao() {
  //   this.saturacao = this.leiturasTanque.map((leituras: any) => leituras.saturacao).slice(-7);

  //   this.gerarGraficoSaturacao();
  // }

  // getOxigenio() {
  //   this.oxigenio = this.leiturasTanqueOxigenio.map((leituras: any) => leituras.oxigenio).slice(-14);

  //   this.gerarGraficoOxigenio();
  // }

  // getTime() {
  //   this.labelsHours = this.leiturasTanque.map((leituras: any) => {
  //     const hour = leituras.hora;
  //     return hour.toString().padStart(2, '0') + ":00";
  //   }).slice(-7);
  // }

  // getTimeOxygen() {
  //   this.labelsHoursOxygen = this.leiturasTanqueOxigenio.map((leituras: any) => {
  //     const hour = leituras.hora;
  //     const minute = leituras.minuto;
  //     return hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0');
  //   }).slice(-14);

  //   console.log(this.labelsHoursOxygen)
  // }


  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  convertEpochToDate(epoch: number): string {
    const date = new Date(epoch);

    const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return timeString;
  }

  // Função para converter de data normal para timestamp (milissegundos)
  convertDateToEpoch(dateString: string): number {
    const date = new Date(dateString);
    return date.getTime();
  }


  gerarGraficos(tanqueId: any): void {

    let dadosTanque = this.tanques[tanqueId];
    this.selectedTanque = dadosTanque.id;

    const chaveOxig = `Tq_${tanqueId + 1}_Oxig`;
    const chaveSat = `Tq_${tanqueId + 1}_Sat`;
    const chaveTemp = `Tq_${tanqueId + 1}_Temp`;

    this.currentTemperatura = dadosTanque['temperatura'].value;
    this.lastHour = this.convertEpochToDate(dadosTanque['temperatura'].ts);

    this.getTemperaturaMaxMinDia(tanqueId);


    this.oxigenio = this.valoresUltimas6HorasOxigenio[chaveOxig].map(item => parseFloat(item.value).toFixed(2));
    this.labelsHoursOxygen = this.valoresUltimas6HorasOxigenio[chaveOxig].map(item => this.convertEpochToDate(item.ts));

    this.saturacao = this.valoresUltimas6HorasSaturacao[chaveSat].map(item => parseFloat(item.value).toFixed(2));
    this.labelsHoursSaturation = this.valoresUltimas6HorasSaturacao[chaveSat].map(item => this.convertEpochToDate(item.ts));


    this.gerarGraficoSaturacao();

    this.gerarGraficoOxigenio();

    this.gerarGraficoAerador();
  }

  gerarGraficoAerador(): void {
    this.chartOptionAerador = {
      tooltip: {
        trigger: 'item', // Exibe o tooltip quando passar o mouse sobre o item
        formatter: '{b}', // Formatação do tooltip, onde {b} é o nome, {c} é o valor e {d} é a porcentagem
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda
        textStyle: {
          color: '#FFF', // Cor do texto
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        },
      },
      legend: {
        show: true, // Ativa a exibição da legenda
        orient: 'horizontal', // Define a orientação da legenda (pode ser 'horizontal' ou 'vertical')
        bottom: '10px',
        left: 'center', // Define a posição da legenda (no lado direito)
        data: [`${this.aeradoresAtivos} ativos`, `${this.aeradoresInativos} inativos`], // Define os itens da legenda (nomes que aparecem)
        textStyle: {
          color: '#333', // Cor do texto da legenda
          fontSize: 14, // Tamanho da fonte da legenda
        },
        itemWidth: 14, // Largura do item da legenda (o retângulo)
        itemHeight: 14, // Altura do item da legenda (o retângulo)
        itemGap: 100,  // Aumenta a distância entre os itens da legenda
      },
      series: [
        {
          name: 'Aeradores',
          type: 'pie',
          radius: ['40%', '70%'], // Cria o gráfico de donut, o primeiro valor é o raio interno e o segundo é o raio externo
          avoidLabelOverlap: false, // Impede que os rótulos sobreponham
          label: {
            show: false,
            formatter: '',
          },
          labelLine: {
            show: true, // Exibe as linhas dos rótulos
          },
          data: [
            { value: this.aeradoresInativos, name: `${this.aeradoresInativos} inativos`, itemStyle: { color: '#165BAA' } },
            { value: this.aeradoresAtivos, name: `${this.aeradoresAtivos} ativos`, itemStyle: { color: '#63ABFD' } },
          ],
        },
      ],
    };
  }


  gerarGraficoPH(): void {
    this.chartOptionPH = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category',
        data: this.labelsHoursSaturation,
        axisLabel: {
          rotate: 0,
          interval: 0
        },
        axisTick: {
          interval: 0
        },
        boundaryGap: false,
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'PH',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'PH',
          type: 'line',
          data: this.ph,
          smooth: false,
          step: 'end',  // Isso fará a linha seguir o estilo de grid (escada)
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6,
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
        }
      ]
    };
  }


  gerarGraficoSaturacao(): void {
    this.chartOptionSaturacao = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsHoursSaturation, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Saturação',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Saturação',
          type: 'line', // Tipo de gráfico 'line'
          data: this.saturacao,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }

  gerarGraficoOxigenio(): void {
    this.chartOptionOxigenio = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsHoursOxygen, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Oxigênio',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Oxigênio',
          type: 'line', // Tipo de gráfico 'line'
          data: this.oxigenio,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#E697FF',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#E697FF ', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }


  cleanInput() {
    const inputsBox = document.querySelectorAll('.form-control') as NodeListOf<HTMLInputElement>;
    const selectsBox = document.querySelectorAll('.form-select') as NodeListOf<HTMLSelectElement>;

    selectsBox.forEach(select => {
      select.selectedIndex = -1;
    });

    this.selectedProdutor = null;
    this.selectedPropriedade = null;
    this.selectedTanque = null;

    this.updateProgressBar();
    this.showGraphs = false;
  }


  // Graficos de Pop-up
  // ----------------------------------------------------------------------------------------------------------------------------------------------------
  openGraph(graphType: string) {
    this.chosenGraph(graphType);

    var overlay = document.getElementById('graphPopUp');
    overlay.style.display = 'block';

    var filterWall = document.getElementById('filterWall');
    filterWall.style.display = 'block';
  }

  closeConfirm() {
    var overlay = document.getElementById('graphPopUp');
    overlay.style.display = 'none';

    var filterWall = document.getElementById('filterWall');
    filterWall.style.display = 'none';
  }

  chosenGraph(graphType: string) {
    this.graphType = graphType;

    this.getEspecificData();

    switch (graphType) {
      case 'aerador':
        this.popTitle = 'Aeradores'
        this.popIcon = 'sprinkler'
        this.popText = 'Quantidade de aeradores ativos e inativos'
        break;

      case 'ph':
        this.popTitle = 'PH da água'
        this.popIcon = 'cadence'
        this.popText = 'Nível de PH da água'
        break;

      case 'temperatura':
        this.popTitle = 'Temperatura da água'
        this.popIcon = 'thermostat'
        this.popText = 'Temperatura máxima e mínima da água'
        break;

      case 'saturação':
        this.popTitle = 'Saturação'
        this.popIcon = 'insert_chart'
        this.popText = 'Nível de saturação'
        break;

      case 'oxigênio':
        this.popTitle = 'Oxigênio'
        this.popIcon = 'bubble_chart'
        this.popText = 'Nível de oxigenação'
        break;
    }
  }


  GerarPopupGraph() {

    switch (this.graphType) {
      case 'aerador':

        break;

      case 'ph':
        this.gerarPopupGraficoPH();
        break;

      case 'temperatura':
        this.gerarPopupGraficoTemperatura();
        break;

      case 'saturação':
        this.gerarPopupGraficoSaturacao();
        break;

      case 'oxigênio':
        this.gerarPopupGraficoOxigenio();
        break;
    }
  }

  selectDateOption(option: string): void {
    this.selectedDateOption = option;
    this.getEspecificData();
  }


  getEspecificData() {

    this.GerarPopupGraph();

  }



  // getFullData(dataType: string) {
  //   this.graphValue = this.leiturasPopup.map((leituras: any) => leituras[dataType]).slice();
  // }

  // getFullTimeDay() {
  //   this.labelsForPopup = this.leiturasPopup.map((leituras: any) => {
  //     const hour = leituras.hora;
  //     return hour.toString().padStart(2, '0') + ":00";
  //   }).slice();
  // }

  // getFullMonth() {
  //   this.labelsForPopup = this.leiturasPopup.map((leituras: any) => leituras.dia).slice();
  // }

  // getFullYear() {
  //   this.labelsForPopup = this.leiturasPopup.map((leituras: any) => {
  //     const data = new Date(2024, leituras.mes - 1, 1);
  //     const mesAbreviado = data.toLocaleString('pt-BR', { month: 'short' }).toLowerCase();
  //     return mesAbreviado;
  //   }).slice();
  // }

  // getFullDataTemp() {
  //   this.GraphMaxTemperatures = this.leiturasPopup.map((leituras: any) => leituras.temperatura_max);
  //   this.GraphMinTemperatures = this.leiturasPopup.map((leituras: any) => leituras.temperatura_min);
  // }


  gerarPopupGraficoPH(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        // this.getFullTimeDay();
        // this.getFullData('ph');
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        // this.getFullMonth();
        // this.getFullData('ph');
        break;

      case 'Ano':
        // this.getFullYear();
        // this.getFullData('ph');
        break;
    }

    this.chartOption = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category',
        data: this.labelsForPopup,
        axisLabel: {
          rotate: 0,
          interval: 0
        },
        axisTick: {
          interval: 0
        },
        boundaryGap: false,
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'PH',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'PH',
          type: 'line',
          data: this.graphValue,
          smooth: false,
          step: 'end',  // Isso fará a linha seguir o estilo de grid (escada)
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6,
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
        }
      ]
    };
  }

  gerarPopupGraficoTemperatura(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        this.getDiaSoTemperatura(this.selectedTanque.split('_')[1], 'Temp');
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        this.getMes(this.selectedTanque.split('_')[1], 'Temp');
        break;

      case 'Ano':
        this.getAno(this.selectedTanque.split('_')[1], 'Temp');
        break;
    }

    this.chartOption = {
      grid: {
        show: true,
        borderColor: '#ECECEC',
        borderWidth: 1,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{c}°', // Exibe nome da categoria (data) e valor da barra
        backgroundColor: '#18181B',
        borderColor: '#ccc',
        borderWidth: 1,
        textStyle: {
          color: '#FFF',
          fontSize: 14,
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category',
        data: this.labelsForPopup, // Certifique-se de que essa variável tem as datas ou categorias
        axisLabel: {
          rotate: 0,
          interval: 0,
        },
        axisTick: {
          interval: 0,
        },
        boundaryGap: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Temperatura',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Temperatura Mínima',
          type: 'bar',
          data: this.GraphMinTemperatures, // A variável com os valores de temperatura mínima
          barWidth: '40%', // Largura das barras
          itemStyle: {
            color: '#E5E5EF', // Cor da barra de temperatura mínima
          },
          barGap: '-100%', // Faz com que as barras fiquem sobrepostas
          z: 1, // A série de temperatura mínima vai para frente
        },
        {
          name: 'Temperatura Máxima',
          type: 'bar',
          data: this.GraphMaxTemperatures, // A variável com os valores de temperatura máxima
          barWidth: '40%', // Largura das barras
          itemStyle: {
            color: '#165BAA', // Cor da barra de temperatura máxima
          },
          barGap: '-100%', // Faz com que as barras fiquem sobrepostas
          z: 0, // A série de temperatura máxima fica atrás
        }
      ]
    };

  }


  gerarPopupGraficoSaturacao(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        this.getDia(this.selectedTanque.split('_')[1], 'Sat');
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        this.getMes(this.selectedTanque.split('_')[1], 'Sat');
        break;

      case 'Ano':
        this.getAno(this.selectedTanque.split('_')[1], 'Sat');
        break;
    }

    this.chartOption = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsForPopup, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Saturação',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Saturação',
          type: 'line', // Tipo de gráfico 'line'
          data: this.graphValue,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }


  gerarPopupGraficoOxigenio(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        this.getDia(this.selectedTanque.split('_')[1], 'Oxig');
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        this.getMes(this.selectedTanque.split('_')[1], 'Oxig');
        break;

      case 'Ano':
        this.getAno(this.selectedTanque.split('_')[1], 'Oxig');
        break;
    }

    this.chartOption = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsForPopup, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Oxigênio',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Oxigênio',
          type: 'line', // Tipo de gráfico 'line'
          data: this.graphValue,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#E697FF',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#E697FF ', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------
}